require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("events/admin/new.js")

document.addEventListener('turbo:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.dropdown-toggle').dropdown()
})

import "controllers"

import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
