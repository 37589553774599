document.addEventListener("turbo:load", function () {
  var tutor = document.getElementById('tutor_save');
  var tutorFields = document.getElementById('tutor-fields');
  tutorFields = document.getElementById('tutor-fields');

  if (tutor) {
    tutor.addEventListener('change', function () {
      if (tutor.checked) {
        tutorFields.classList.add('d-block');
      } else {
        tutorFields.classList.remove('d-block');
        tutorFields.classList.add('d-none');
      }
    })
  }
});
